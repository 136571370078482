import Table from '@mui/material/Table';
import styles from './styles.module.scss';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useEffect } from 'react';
import { Button, Nav } from '../../components';
import { useLocation } from 'react-router-dom';
import { AddEditUserModalContent } from '../../modal-contents';

const TABLE_HEAD_ITEMS = ['username', 'role', 'edit'];

const Users = ({
  isDarkMode,
  users,
  setModalContentAction,
  getAllUsersAction,
  updateUserAction,
  deleteUserAction,
  addUserAction,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    getAllUsersAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addUser = () => {
    setModalContentAction(
      <AddEditUserModalContent isDarkMode={isDarkMode} addUserAction={addUserAction} />
    );
  };

  const editUser = ({ username, role, id }) => {
    const dataContent = { username, role, id };
    setModalContentAction(
      <AddEditUserModalContent
        isDarkMode={isDarkMode}
        dataContent={dataContent}
        updateUserAction={updateUserAction}
        deleteUserAction={deleteUserAction}
      />
    );
  };

  return (
    <section className={styles['Users']}>
      <Nav pathname={pathname} />

      <div className={styles['toolbar']}>
        <div className={styles['btn-wrapper']}>
          <Button onClick={addUser}>Add User</Button>
        </div>
      </div>

      <div className={styles['table']}>
        <Table sx={{ minWidth: 250, color: 'inherit' }} size="small">
          <TableHead>
            <TableRow>
              {TABLE_HEAD_ITEMS.map((title, i) => (
                <TableCell
                  className={styles['table-head-cell']}
                  key={title}
                  align={i ? 'right' : 'left'}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((data) => (
              <TableRow key={data.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" className={styles['table-cell']}>
                  {data.username}
                </TableCell>
                <TableCell align="right" className={styles['table-cell']}>
                  {data.role}
                </TableCell>
                <TableCell align="right" className={styles['table-cell']}>
                  <IconButton color="inherit" component="label" onClick={() => editUser(data)}>
                    <ModeEditIcon color="inherit" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </section>
  );
};

export { Users };
