import {
  UPDATE_FINANCE_TYPE,
  DELETE_FINANCE_TYPE,
  SAVE_FINANCE_TYPES,
  SAVE_FINANCE_TYPE,
} from './actionTypes';

const updateFinanceType = (financeType) => ({
  type: UPDATE_FINANCE_TYPE,
  payload: { financeType },
});
const deleteFinanceType = (id) => ({ type: DELETE_FINANCE_TYPE, payload: { id } });
const saveFinanceTypes = (items) => ({ type: SAVE_FINANCE_TYPES, payload: { items } });
const saveFinanceType = (financeType) => ({ type: SAVE_FINANCE_TYPE, payload: { financeType } });

export { updateFinanceType, deleteFinanceType, saveFinanceTypes, saveFinanceType };
