import {
  UPDATE_FINANCE_TYPE,
  DELETE_FINANCE_TYPE,
  SAVE_FINANCE_TYPES,
  SAVE_FINANCE_TYPE,
} from './actionTypes';

const initialState = {
  financeTypes: [],
};

const financeTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FINANCE_TYPES: {
      const { items } = action.payload;
      return {
        ...state,
        financeTypes: items,
      };
    }
    case SAVE_FINANCE_TYPE: {
      const { financeType } = action.payload;
      return {
        ...state,
        financeTypes: [...state.financeTypes, financeType],
      };
    }
    case UPDATE_FINANCE_TYPE: {
      const { financeType } = action.payload;
      return {
        ...state,
        financeTypes: [...state.financeTypes].map((stateFinanceType) =>
          stateFinanceType.id === financeType.id ? financeType : stateFinanceType
        ),
      };
    }
    case DELETE_FINANCE_TYPE: {
      const { id } = action.payload;
      return {
        ...state,
        financeTypes: [...state.financeTypes].filter(
          (stateFinanceType) => stateFinanceType.id !== id
        ),
      };
    }
    default: {
      return { ...state };
    }
  }
};

export { financeTypesReducer };
