import { DELETE_USER, SAVE_USER } from './actionTypes';

const initialState = {
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case DELETE_USER: {
      return {
        ...state,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export { authReducer };
