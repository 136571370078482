import styles from './styles.module.scss';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '../../components/button';
import { ROLE_NAME_ADMIN } from '../../common/constants';

const LenderItem = ({ dataContent, userRole, handler, onEdit }) => {
  const { title, image, id } = dataContent;

  const editHandler = () => {
    onEdit();
  };

  return (
    <div className={styles['LenderItem']}>
      <div className={styles['image-wrapper']}>
        {image && <img className={styles['image']} src={image} alt="LenderLogo" />}
      </div>

      {userRole === ROLE_NAME_ADMIN && (
        <div className={styles['edit-btn-wrapper']}>
          <IconButton color="inherit" component="label" onClick={editHandler}>
            <MoreVertIcon color="inherit" />
          </IconButton>
        </div>
      )}

      <div className={styles['content-wrapper']}>
        <h2 className={styles['title']}>{title}</h2>
        <div className={styles['btn-wrapper']}>
          <Button onClick={() => handler(dataContent)}>learn more</Button>
        </div>
      </div>
    </div>
  );
};

export { LenderItem };
