import { connect } from 'react-redux';
import { FinanceTypes } from './FinanceTypes';
import { setModalContentAction } from '../../redux/settings/actions';
import {
  clearLenderFilterDiapasonsAction,
  getLenderFilterDiapasonsAction,
  getAllLendersAction,
} from '../../redux/lenders/actions';
import {
  getAllFinanceTypesAction,
  updateFinanceTypeAction,
  deleteFinanceTypeAction,
  addFinanceTypeAction,
} from '../../redux/finance-types/actions';

const mapStateToProps = (state) => ({
  financeTypes: state.financeTypes.financeTypes,
  isDarkMode: state.settings.isDarkMode,
  user: state.auth.user,
});

const mapDispatchToProps = {
  clearLenderFilterDiapasonsAction,
  getLenderFilterDiapasonsAction,
  getAllFinanceTypesAction,
  updateFinanceTypeAction,
  deleteFinanceTypeAction,
  setModalContentAction,
  addFinanceTypeAction,
  getAllLendersAction,
};

const ReduxFinanceTypes = connect(mapStateToProps, mapDispatchToProps)(FinanceTypes);

export { ReduxFinanceTypes as FinanceTypes };
