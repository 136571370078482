import 'react-toastify/dist/ReactToastify.css';
import { Router } from './router';
import { Layout } from './layout';
import { Provider } from 'react-redux';
import { storeConfiguration } from './redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

export const store = storeConfiguration();

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <Layout>
            <Router />
          </Layout>
        </Provider>
        <ToastContainer />
      </BrowserRouter>
    </>
  );
};

export { App };
