import { toast } from 'react-toastify';

class NotificationService {
  constructor() {
    this.options = {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000,
      hideProgressBar: false,
      pauseOnHover: true,
    };
  }

  success(text) {
    return toast.success(text, {
      ...this.options,
      type: toast.TYPE.SUCCESS,
      className: 'success-toast',
    });
  }

  info(text) {
    return toast.info(text, {
      ...this.options,
      type: toast.TYPE.INFO,
    });
  }

  error(text) {
    return toast.error(text, {
      ...this.options,
      type: toast.TYPE.ERROR,
      className: 'error-toast',
    });
  }

  default(text) {
    toast(text, this.options);
  }
}

const notificationService = new NotificationService();

export { notificationService };
