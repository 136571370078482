import {
  CLEAR_LENDER_FILTER_DIAPASONS,
  SET_LENDER_FILTER_DIAPASONS,
  SET_FILTERED_LENDER,
  UPDATE_LENDER,
  DELETE_LENDER,
  SAVE_LENDERS,
  SAVE_LENDER,
} from './actionTypes';

const updateLender = (lender, parentFinanceTypeId) => ({
  type: UPDATE_LENDER,
  payload: { parentFinanceTypeId, lender },
});
const saveLender = (lender, parentFinanceTypeId) => ({
  type: SAVE_LENDER,
  payload: { parentFinanceTypeId, lender },
});
const deleteLender = (id) => ({ type: DELETE_LENDER, payload: { id } });
const saveLenders = (items) => ({ type: SAVE_LENDERS, payload: { items } });

const setLenderFilterDiapasons = (filterDiapasons) => ({
  type: SET_LENDER_FILTER_DIAPASONS,
  payload: { filterDiapasons },
});

const clearLenderFilterDiapasons = () => ({
  type: CLEAR_LENDER_FILTER_DIAPASONS,
});

const setFilteredLender = (isReverse) => ({
  type: SET_FILTERED_LENDER,
  payload: { isReverse },
});

export {
  clearLenderFilterDiapasons,
  setLenderFilterDiapasons,
  setFilteredLender,
  updateLender,
  deleteLender,
  saveLenders,
  saveLender,
};
