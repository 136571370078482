import { ApiCallService } from '../../services';

class UsersApiCalls extends ApiCallService {
  constructor(apiUrl) {
    super(apiUrl);
  }

  async getAllUsers() {
    const url = '/';
    return this.get(url);
  }

  async addUser(data) {
    const url = '/';
    return this.send(url, data);
  }

  async updateUser(data) {
    const url = '/';
    return this.update(url, data);
  }

  async deleteUser(id) {
    const url = `/${id}`;
    return this.delete(url);
  }
}

const apiUrl = '/user';

const usersApiCalls = new UsersApiCalls(apiUrl);

export { usersApiCalls };
