import styles from './styles.module.scss';
import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

const CssFormControlLabel = styled((props) => <FormControlLabel {...props} />)(() => ({
  '& .Mui-disabled ': {
    color: '#828282 !important',
  },
}));

const Checkbox = ({
  isDisabled = false,
  isChecked = false,
  dataKey = '',
  label,
  name,
  onChange,
}) => {
  const handler = (e) => {
    onChange(e);
  };

  return (
    <CssFormControlLabel
      label={label}
      control={
        <MaterialCheckbox
          disabled={isDisabled}
          className={styles['Checkbox']}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          checked={isChecked}
          name={name}
          inputProps={{ ...{ 'data-key': dataKey } }}
          onChange={handler}
        />
      }
    />
  );
};

export { Checkbox };
