import { Router } from './Router';
import { connect } from 'react-redux';
import { saveUserAction } from '../redux/auth/actions';
import { setLoadingStateAction, setModalContentAction } from '../redux/settings/actions';

const mapStateToProps = (state) => ({
  modalContent: state.settings.modalContent,
  isDarkMode: state.settings.isDarkMode,
  isLoading: state.settings.isLoading,
  user: state.auth.user,
});
const mapDispatchToProps = {
  setLoadingStateAction,
  setModalContentAction,
  saveUserAction,
};

const ReduxRouter = connect(mapStateToProps, mapDispatchToProps)(Router);

export { ReduxRouter as Router };
