const toDivideNumber = (number) => {
  const str = String(number);
  if (str.length <= 3) return str;
  let space = 0;
  let result = '';

  for (let i = str.length - 1; i >= 0; i--) {
    if (space == 3) {
      result = ',' + result;
      space = 0;
    }
    result = str.charAt(i) + result;
    space++;
  }

  return result;
};

export { toDivideNumber };
