import cn from 'classnames';
import styles from './styles.module.scss';
import MaterialButton from '@mui/material/Button';

const Button = ({ children, isError, onClick }) => {
  return (
    <MaterialButton
      onClick={onClick}
      variant="contained"
      className={cn(styles['Button'], {
        [styles['Button--error']]: isError,
      })}
    >
      {children}
    </MaterialButton>
  );
};

export { Button };
