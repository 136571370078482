import { Lenders } from './Lenders';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setModalContentAction } from '../../redux/settings/actions';
import { getAllFinanceTypesAction } from '../../redux/finance-types/actions';
import {
  setFilteredLenderAction,
  updateLenderAction,
  deleteLenderAction,
  addLenderAction,
} from '../../redux/lenders/actions';

const selectLenderItems = (state) => state.lenders.lenders;
const selectLenders = createSelector(selectLenderItems, (items) => {
  const setStrValueItems = (incomingItem) =>
    Array.isArray(incomingItem) ? incomingItem.map(({ name }) => name) : [];

  const setBooleanValue = (value) => (typeof value === 'boolean' ? value : 0);

  return items.map((data) => {
    return {
      ...data,
      is_early_repayment_charges: setBooleanValue(data?.is_early_repayment_charges),
      is_personal_guarantee: setBooleanValue(data?.is_personal_guarantee),
      is_owner_occupant: setBooleanValue(data?.is_owner_occupant),
      is_interest_only: setBooleanValue(data?.is_interest_only),

      treatment_interest: setStrValueItems(data?.lender_treatment_interests),
      covered_location: setStrValueItems(data?.lender_covered_locations),
      experience_type: setStrValueItems(data?.lender_experience_types),
      valuation_type: setStrValueItems(data?.lender_valuation_types),
    };
  });
});

const mapStateToProps = (state) => {
  const lenders = selectLenders(state);
  return {
    financeTypes: state.financeTypes.financeTypes,
    isDarkMode: state.settings.isDarkMode,
    lenders,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  getAllFinanceTypesAction,
  setFilteredLenderAction,
  setModalContentAction,
  updateLenderAction,
  deleteLenderAction,
  addLenderAction,
};
const ReduxLenders = connect(mapStateToProps, mapDispatchToProps)(Lenders);

export { ReduxLenders as Lenders };
