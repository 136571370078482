/* eslint-disable no-debugger */
import cn from 'classnames';
import styles from './styles.module.scss';
import MaterialDrawer from '@mui/material/Drawer';
import { toDivideNumber } from '../../helpers';
import { useWindowSize } from '../../custom-hooks';
import { useLayoutEffect, useEffect, useReducer } from 'react';
import { MultipleCheckboxItems, Accordion, Slider, Button, Input } from '../';
import { GROUP_MULTIPLE_VALUE_ITEMS, BOOLEAN_DATA_ITEMS } from '../../common/constants';
import { createSearchParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';

const numDefaultValue = 0;

const combinedDiapasonItems = [
  {
    title: 'Rate (%)',
    components: [{ key: 'min_rate' }, { key: 'max_rate' }],
  },
  {
    title: 'Loan (£)',
    components: [{ key: 'min_loan' }, { key: 'max_loan' }],
  },
  {
    title: 'Fixed rate term (years)',
    components: [{ key: 'min_fixed_rate_term' }, { key: 'max_fixed_rate_term' }],
  },
  {
    title: 'Term (years)',
    components: [{ key: 'min_term' }, { key: 'max_term' }],
  },
  {
    title: 'Age (years)',
    components: [{ key: 'min_age' }, { key: 'max_age' }],
  },
  {
    title: 'Loan processing time (days)',
    components: [{ key: 'min_loan_processing_time' }, { key: 'max_loan_processing_time' }],
  },
  {
    title: 'Decision time (hours)',
    components: [{ key: 'min_decision_time' }, { key: 'max_decision_time' }],
  },
  // {
  //   title: 'Trading period (months)',
  //   components: [{ key: 'min_trading_period' }, { key: 'max_trading_period' }],
  // },
  // {
  //   title: 'Loan to value (%)',
  //   components: [{ key: 'max_loan_to_value' }, { key: 'max_loan_to_value' }],
  // },
];

const selectVariantItems = [
  {
    value: numDefaultValue,
    label: 'Not selected',
  },
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const initialState = {
  financeTypeId: numDefaultValue,

  /* percents */
  min_rate: numDefaultValue,
  max_rate: numDefaultValue,

  /* £ */
  min_loan: numDefaultValue,
  max_loan: numDefaultValue,

  /* years */
  min_fixed_rate_term: numDefaultValue,
  max_fixed_rate_term: numDefaultValue,

  /* years */
  min_term: numDefaultValue,
  max_term: numDefaultValue,

  /* years */
  min_age: numDefaultValue,
  max_age: numDefaultValue,

  /* days */
  min_loan_processing_time: numDefaultValue,
  max_loan_processing_time: numDefaultValue,

  /* hours */
  min_decision_time: numDefaultValue,
  max_decision_time: numDefaultValue,

  /* months */
  min_trading_period: numDefaultValue,
  max_trading_period: numDefaultValue, // ?

  /* percents */
  min_loan_to_value: numDefaultValue, // ?
  max_loan_to_value: numDefaultValue,

  is_early_repayment_charges: numDefaultValue,
  is_personal_guarantee: numDefaultValue,
  is_owner_occupant: numDefaultValue,
  is_interest_only: numDefaultValue,

  treatment_interest: [],
  covered_location: [],
  experience_type: [],
  valuation_type: [],
};

function reducer(state, action) {
  switch (action.type) {
    /* for inputs */
    case 'SAVE_INPUT_VALUE': {
      const { value, key } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }
    /* for inputs */

    /* for checkboxes group */
    case 'SAVE_CHECKBOX_ITEM': {
      const { value, key } = action.payload;
      return {
        ...state,
        [key]: [...state[key], value],
      };
    }
    case 'REMOVE_CHECKBOX_ITEM': {
      const { value, key } = action.payload;
      return {
        ...state,
        [key]: [...state[key].filter((stateValue) => stateValue !== value)],
      };
    }
    /* for checkboxes group */

    /* for checkboxes */
    case 'SAVE_CHECKBOX_VALUE': {
      const { value, key } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }
    /* for checkboxes */

    /* for sliders */
    case 'SAVE_SLIDER_VALUE': {
      const payload = action.payload;
      return {
        ...state,
        ...payload,
      };
    }
    /* for sliders */

    case 'RESET': {
      return {
        ...state,
        ...initialState,
      };
    }

    case 'RESET_FILTER': {
      return {
        ...state,
        ...initialState,
        financeTypeId: state.financeTypeId,
      };
    }

    case 'SET': {
      const { newState } = action.payload;
      return {
        ...state,
        ...newState,
      };
    }

    default: {
      return { ...state };
    }
  }
}

const stateValidation = (state) => {
  const values = Object.values(state);
  const keys = Object.keys(state);
  const data = {};

  for (const i in values) {
    const value = values[i];
    /* if value is empty || value !== false(default checkboxes) -> continue */
    if (value !== false && !value) continue;

    /* if array is empty -> continue */
    if (Array.isArray(value) && !value.length) continue;

    data[keys[i]] = value;
  }

  return data;
};

const Drawer = ({
  filterDiapasons,
  financeTypes,
  isDrawerOpen,
  isDarkMode,
  clearLenderFilterDiapasonsAction,
  getLenderFilterDiapasonsAction,
  setDrawerStateAction,
  getAllLendersAction,
}) => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const financeTypeId = searchParams.get('financeTypeId');
  const stateFinanceTypeId = state.financeTypeId;
  useEffect(() => {
    // Number(financeTypeId) && getLenderFilterDiapasonsAction(financeTypeId) && onApply(false);
    Number(financeTypeId) && getAllLendersAction({ financeTypeId: Number(financeTypeId) });
    Number(financeTypeId) &&
      dispatch({
        type: 'SAVE_INPUT_VALUE',
        payload: { value: Number(financeTypeId), key: 'financeTypeId' },
      });
  }, [financeTypeId]);
  useLayoutEffect(() => {
    pathname === '/login' &&
      dispatch({
        type: 'SAVE_INPUT_VALUE',
        payload: { value: 0, key: 'financeTypeId' },
      });
  }, [pathname]);
  useEffect(() => {
    const isCloseDrawer = false;
    stateFinanceTypeId &&
      getLenderFilterDiapasonsAction(stateFinanceTypeId) &&
      onApply(isCloseDrawer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFinanceTypeId]);

  const { width } = useWindowSize();
  const isMobile = width <= 620;

  const closeDrawer = () => {
    setDrawerStateAction(false);
  };

  const checkboxHandler = ({ target, target: { checked, name } }) => {
    const key = target.getAttribute('data-key');
    if (
      key === 'treatment_interest' ||
      key === 'covered_location' ||
      key === 'experience_type' ||
      key === 'valuation_type'
    ) {
      const type = checked ? 'SAVE_CHECKBOX_ITEM' : 'REMOVE_CHECKBOX_ITEM';
      dispatch({ type, payload: { value: name, key } });
      return;
    }

    dispatch({ type: 'SAVE_CHECKBOX_VALUE', payload: { value: checked, key: name } });
  };

  const inputHandler = ({ target: { value, name } }) => {
    if (name === 'financeTypeId') {
      /* when we change financeTypeId field -> before we reset all state */
      dispatch({ type: 'RESET' });
      clearLenderFilterDiapasonsAction();
      return dispatch({ type: 'SAVE_INPUT_VALUE', payload: { value, key: name } });
    }

    dispatch({ type: 'SAVE_INPUT_VALUE', payload: { value, key: name } });
  };

  const onApply = async (isCloseDrawer = true) => {
    const validatedData = stateValidation(state);
    const items = await getAllLendersAction(validatedData);
    items &&
      navigate({
        pathname: '/finance-types/lenders',
        search: `?${createSearchParams({
          financeTypeId: stateFinanceTypeId,
        })}`,
      });
    isCloseDrawer && closeDrawer();
  };

  const onReset = () => {
    dispatch({ type: 'RESET_FILTER' });
  };

  const sliderHandle = ({ target: { name } }, newValue) => {
    const [min, max] = newValue;
    const payload = { [`min_${name}`]: min, [`max_${name}`]: max };
    console.log([min, max], name, payload);
    dispatch({ type: 'SAVE_SLIDER_VALUE', payload });
  };

  const selectFinanceTypeItems = [
    {
      value: numDefaultValue,
      label: 'Not selected',
    },
    ...(financeTypes || []).map(({ title, id }) => ({
      value: id,
      label: title,
    })),
  ];

  const sliderInputHandleChange = ({ target }) => {
    const inputValue = Number(target.value.replace(/,/g, ''));
    const inputName = target.name;
    const diapasonName = inputName.replace(/^(min|max)_/, '');
    const diapasonValue = inputName.substring(0, 3); // min|max

    const payload = { target: { name: diapasonName } };
    // console.log({ newValue: diapasonValue });
    switch (true) {
      case diapasonValue === 'min' &&
        inputValue <= filterDiapasons[`max_${diapasonName}`] &&
        inputValue >= filterDiapasons[`min_${diapasonName}`]: {
        const newValue = [inputValue, state[`max_${diapasonName}`]];
        console.log({ newValue });
        sliderHandle(payload, newValue);
        break;
      }
      case diapasonValue === 'max' &&
        inputValue >= filterDiapasons[`min_${diapasonName}`] &&
        inputValue <= filterDiapasons[`max_${diapasonName}`]: {
        const newValue = [state[`min_${diapasonName}`], inputValue];
        sliderHandle(payload, newValue);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <MaterialDrawer
      sx={{
        width: '300px',
        flexShrink: 0,
        height: '100%',
        [`& .MuiDrawer-paper`]: {
          height: '100%',
          width: '300px',
          position: isMobile ? 'fixed' : 'static',
          backgroundColor: isDarkMode ? '#2E2A29' : '#fff',
          borderRightColor: isDarkMode ? '#3c6e8b' : '#0000001a',
        },
      }}
      variant={isMobile ? 'temporary' : 'permanent'}
      anchor={isMobile ? 'left' : undefined}
      open={isDrawerOpen}
      onClose={closeDrawer}
    >
      <div
        className={cn(styles['Drawer'], {
          [styles['Drawer--dark']]: isDarkMode,
        })}
      >
        {/* select finance types */}
        <div className={styles['content-item']}>
          <Input
            label="Finance type"
            name="financeTypeId"
            isDarkMode={isDarkMode}
            value={stateFinanceTypeId}
            onChange={inputHandler}
            defaultValue={stateFinanceTypeId}
            selectItems={selectFinanceTypeItems}
          />
        </div>

        {Boolean(stateFinanceTypeId) && (
          <>
            {combinedDiapasonItems.map(({ title, components }, i) => {
              /* { title: stringify, components: Array<{ key: string }> } */
              const minKey = components[0].key;
              const maxKey = components[1].key;

              const minDbDiapason = filterDiapasons[minKey];
              const maxDbDiapason = filterDiapasons[maxKey];

              const name = minKey.replace(/^(min)_/, '');
              const isAdditionalInput = name === 'loan';

              const minValue = Number(state[minKey]) || minDbDiapason;
              const maxValue = Number(state[maxKey]) || maxDbDiapason;

              return minDbDiapason !== maxDbDiapason ? (
                <div key={i} className={styles['content-item']}>
                  <Accordion title={title}>
                    <div className={styles['slider-wrapper']}>
                      {isAdditionalInput && (
                        <div className={styles['slider-input-container']}>
                          <div className={styles['slider-input-wrapper']}>
                            <Input
                              name={minKey}
                              label="min"
                              value={toDivideNumber(minValue)}
                              onChange={sliderInputHandleChange}
                            />
                          </div>
                          <div className={styles['slider-input-wrapper']}>
                            <Input
                              name={maxKey}
                              label="max"
                              value={toDivideNumber(maxValue)}
                              onChange={sliderInputHandleChange}
                            />
                          </div>
                        </div>
                      )}

                      <Slider
                        name={name}
                        min={minDbDiapason}
                        max={maxDbDiapason}
                        value={[minValue, maxValue]}
                        onChange={sliderHandle}
                      />
                    </div>
                  </Accordion>
                </div>
              ) : (
                <span key={i}></span>
              );
            })}

            <MultipleCheckboxItems
              filterDiapasons={filterDiapasons}
              dataContent={state}
              groupItems={GROUP_MULTIPLE_VALUE_ITEMS}
              isEdit={false}
              checkboxHandler={checkboxHandler}
            />

            {BOOLEAN_DATA_ITEMS.map(({ title, key }, i) => {
              return filterDiapasons[key] ? (
                <div key={i} className={styles['content-item']}>
                  <Input
                    label={title}
                    name={key}
                    isDarkMode={isDarkMode}
                    value={state[key]}
                    onChange={inputHandler}
                    defaultValue={numDefaultValue}
                    selectItems={selectVariantItems}
                  />
                </div>
              ) : (
                <span key={i}></span>
              );
            })}

            {!Object.values(filterDiapasons).every((value) => value === 0) && (
              <div className={styles['btn-wrapper']}>
                <div className={styles['btn']}>
                  <Button isError onClick={onReset}>
                    Reset
                  </Button>
                </div>
                <div className={styles['btn']}>
                  <Button onClick={onApply}>Apply</Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </MaterialDrawer>
  );
};

export { Drawer };
