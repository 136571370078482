import styles from './styles.module.scss';
import { useState } from 'react';
import { Button, Input } from '../../components';
import { notificationService } from '../../services';
import { ROLE_NAME_ADMIN, ROLE_NAME_USER } from '../../common/constants';

const selectItems = [
  {
    value: ROLE_NAME_ADMIN,
    label: ROLE_NAME_ADMIN,
  },
  {
    value: ROLE_NAME_USER,
    label: ROLE_NAME_USER,
  },
];

const AddEditUserModalContent = ({
  isDarkMode,
  dataContent = {},
  updateUserAction,
  deleteUserAction,
  addUserAction,
}) => {
  const defaultRole = ROLE_NAME_USER;
  const roleValue = dataContent.role ? dataContent.role : defaultRole;
  const [role, setRole] = useState(roleValue);
  const [username, setUsername] = useState(dataContent.username || '');
  const [password, setPassword] = useState(dataContent.password || '');
  const isEdit = Boolean(dataContent.id); // if dataContent has id -> is edit mode

  const userHandler = () => {
    if (isEdit && (!username || !role)) {
      return notificationService.error('All username and role are required');
    }

    if (!isEdit && (!username || !password || !role)) {
      return notificationService.error('All fields are required');
    }

    const data = { username, password, role, id: dataContent.id };
    isEdit ? updateUserAction(data) : addUserAction(data);
  };

  const inputHandler = ({ target: { value, name } }) => {
    name === 'password' && setPassword(value);
    name === 'username' && setUsername(value);
    name === 'role' && setRole(value);
  };

  const deleteUser = () => {
    const userId = dataContent.id;
    deleteUserAction(userId);
  };

  return (
    <div className={styles['AddEditUserModalContent']}>
      <div className={styles['input-wrapper']}>
        <Input
          label="Username"
          name="username"
          isDarkMode={isDarkMode}
          value={username}
          onChange={inputHandler}
        />
      </div>
      <div className={styles['input-wrapper']}>
        <Input
          label="Password"
          name="password"
          isDarkMode={isDarkMode}
          value={password}
          onChange={inputHandler}
        />
      </div>
      <div className={styles['input-wrapper']}>
        <Input
          label="Role"
          name="role"
          isDarkMode={isDarkMode}
          value={role}
          onChange={inputHandler}
          defaultValue={roleValue}
          selectItems={selectItems}
        />
      </div>
      <div className={styles['btn-wrapper']}>
        <Button onClick={userHandler}>{isEdit ? 'Edit user' : 'Add User'}</Button>
      </div>

      {isEdit && (
        <div className={styles['delete-btn-wrapper']}>
          <Button isError onClick={deleteUser}>
            Delete user
          </Button>
        </div>
      )}
    </div>
  );
};

export { AddEditUserModalContent };
