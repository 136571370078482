import styles from './styles.module.scss';
import { Accordion, Checkbox } from '../';

const MultipleCheckboxItem = ({ dataContent, isEdit, items, dataKey, title, checkboxHandler }) => (
  <Accordion title={title} isExpanded={isEdit}>
    {items.map((name, j) => {
      const dataContentItems = dataContent[dataKey];
      const isMatch = dataContentItems.find((dataContentName) => dataContentName === name);
      const isChecked = Boolean(isMatch);

      return (
        <div key={j} className={styles['checkbox-wrapper']}>
          <Checkbox
            dataKey={dataKey}
            isChecked={isChecked}
            label={name}
            name={name}
            onChange={checkboxHandler}
          />
        </div>
      );
    })}
  </Accordion>
);

const MultipleCheckboxItems = ({
  filterDiapasons = false,
  dataContent,
  groupItems,
  isEdit,
  checkboxHandler,
}) => {
  return (
    <>
      {groupItems.map(({ items, dataKey, title }, i) => {
        return filterDiapasons ? (
          filterDiapasons[`lender_${dataKey}s`] ? (
            <div key={i} className={styles['MultipleCheckboxItems']}>
              <MultipleCheckboxItem
                dataContent={dataContent}
                dataKey={dataKey}
                isEdit={isEdit}
                title={title}
                items={items}
                checkboxHandler={checkboxHandler}
              />
            </div>
          ) : (
            <span key={i}></span>
          )
        ) : (
          <div key={i} className={styles['MultipleCheckboxItems']}>
            <MultipleCheckboxItem
              dataContent={dataContent}
              dataKey={dataKey}
              isEdit={isEdit}
              title={title}
              items={items}
              checkboxHandler={checkboxHandler}
            />
          </div>
        );
      })}
    </>
  );
};

export { MultipleCheckboxItems };
