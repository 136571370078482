import { setLoadingState, setDrawerState, setModalContent, setDarkMode } from './actionCreators';

const setLoadingStateAction = (isLoading) => (dispatch) => dispatch(setLoadingState(isLoading));

const setDarkModeAction = (isDarkMode) => (dispatch) => dispatch(setDarkMode(isDarkMode));

const setDrawerStateAction = (isDrawerOpen) => (dispatch) => dispatch(setDrawerState(isDrawerOpen));

const setModalContentAction = (content) => (dispatch) => dispatch(setModalContent(content));

export { setLoadingStateAction, setDrawerStateAction, setModalContentAction, setDarkModeAction };
