import { Layout } from './Layout';
import { connect } from 'react-redux';
import { logOutAction } from '../redux/auth/actions';
import { setDrawerStateAction, setDarkModeAction } from '../redux/settings/actions';
import {
  clearLenderFilterDiapasonsAction,
  getLenderFilterDiapasonsAction,
  getAllLendersAction,
} from '../redux/lenders/actions';

const mapStateToProps = (state) => ({
  filterDiapasons: state.lenders.filterDiapasons,
  financeTypes: state.financeTypes.financeTypes,
  isDrawerOpen: state.settings.isDrawerOpen,
  isDarkMode: state.settings.isDarkMode,
});

const mapDispatchToProps = {
  clearLenderFilterDiapasonsAction,
  getLenderFilterDiapasonsAction,
  setDrawerStateAction,
  getAllLendersAction,
  setDarkModeAction,
  logOutAction,
};

const ReduxLayout = connect(mapStateToProps, mapDispatchToProps)(Layout);

export { ReduxLayout as Layout };
