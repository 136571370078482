import { UPDATE_USER, DELETE_USER, SAVE_USERS, SAVE_USER } from './actionTypes';

const initialState = {
  users: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USERS: {
      const { items } = action.payload;
      return {
        ...state,
        users: items,
      };
    }
    case SAVE_USER: {
      const { user } = action.payload;
      return {
        ...state,
        users: [...state.users, user],
      };
    }
    case UPDATE_USER: {
      const { user } = action.payload;
      return {
        ...state,
        users: [...state.users].map((stateUser) => (stateUser.id === user.id ? user : stateUser)),
      };
    }
    case DELETE_USER: {
      const { id } = action.payload;
      return {
        ...state,
        users: [...state.users].filter((stateUser) => stateUser.id !== id),
      };
    }
    default: {
      return { ...state };
    }
  }
};

export { usersReducer };
