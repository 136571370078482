import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { ROLE_NAME_ADMIN } from '../../common/constants';
import { FinanceTypeItem, AddItem, Nav } from '../../components';
import { AddEditFinanceTypeModalContent } from '../../modal-contents';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

const FinanceTypes = ({
  clearLenderFilterDiapasonsAction,
  getLenderFilterDiapasonsAction,
  financeTypes,
  isDarkMode,
  user,
  getAllFinanceTypesAction,
  updateFinanceTypeAction,
  deleteFinanceTypeAction,
  setModalContentAction,
  addFinanceTypeAction,
  getAllLendersAction,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    getAllFinanceTypesAction();
  }, []);

  const itemHandler = async (financeTypeId) => {
    clearLenderFilterDiapasonsAction();
    const params = { financeTypeId };
    await getLenderFilterDiapasonsAction(params);
    await getAllLendersAction(params);
    navigate({
      pathname: '/finance-types/lenders',
      search: `?${createSearchParams({
        financeTypeId,
      })}`,
    });
  };

  const addItemHandler = () => {
    setModalContentAction(
      <AddEditFinanceTypeModalContent
        isDarkMode={isDarkMode}
        addFinanceTypeAction={addFinanceTypeAction}
      />
    );
  };

  const editItemHandler = (data) => {
    setModalContentAction(
      <AddEditFinanceTypeModalContent
        isDarkMode={isDarkMode}
        dataContent={data}
        updateFinanceTypeAction={updateFinanceTypeAction}
        deleteFinanceTypeAction={deleteFinanceTypeAction}
        addFinanceTypeAction={addFinanceTypeAction}
      />
    );
  };

  return (
    <section className={styles['FinanceTypes']}>
      <Nav pathname={pathname} userRole={user.role} />

      <ul className={styles['content']}>
        {financeTypes.map((item, i) => (
          <li key={i} className={styles['content-finance-type-item']}>
            <FinanceTypeItem
              dataContent={item}
              userRole={user.role}
              handler={itemHandler}
              onEdit={() => editItemHandler(item)}
            />
          </li>
        ))}

        {user.role === ROLE_NAME_ADMIN && (
          <li className={styles['content-finance-type-item']}>
            <AddItem handler={addItemHandler} />
          </li>
        )}
      </ul>
    </section>
  );
};

export { FinanceTypes };
