import {
  SET_DARK_MODE_STATE,
  SET_LOADING_STATE,
  SET_MODAL_CONTENT,
  SET_DRAWER_STATE,
} from './actionTypes';

const initialState = {
  isDrawerOpen: false,
  isDarkMode: false,
  isLoading: false,
  modalContent: null,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_STATE: {
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading,
      };
    }
    case SET_DARK_MODE_STATE: {
      const { isDarkMode } = action.payload;
      return {
        ...state,
        isDarkMode,
      };
    }
    case SET_DRAWER_STATE: {
      const { isDrawerOpen } = action.payload;
      return {
        ...state,
        isDrawerOpen,
      };
    }
    case SET_MODAL_CONTENT: {
      const { content } = action.payload;
      return {
        ...state,
        modalContent: content,
      };
    }
    default:
      return { ...state };
  }
};

export { settingsReducer };
