import { lendersApiCalls } from './apiCalls';
import { notificationService } from '../../services';
import { setModalContent } from '../settings/actionCreators';
import {
  clearLenderFilterDiapasons,
  setLenderFilterDiapasons,
  setFilteredLender,
  updateLender,
  deleteLender,
  saveLenders,
  saveLender,
} from './actionCreators';

const getAllLendersAction =
  (params = {}) =>
  async (dispatch) => {
    const res = await lendersApiCalls.getAllLenders(params);
    if (!res) return null;

    const items = res.items;
    dispatch(saveLenders(items));
    return items;
  };

const addLenderAction = (data, parentFinanceTypeId) => async (dispatch) => {
  const res = await lendersApiCalls.addLender(data);
  if (!res) return null;

  const lender = res.data?.lender;
  dispatch(saveLender(lender, parentFinanceTypeId));

  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('Lender added successfully');
};

const updateLenderAction = (data, parentFinanceTypeId) => async (dispatch) => {
  const res = await lendersApiCalls.updateLender(data);
  if (!res) return null;

  const lender = res.data?.lender;
  dispatch(updateLender(lender, parentFinanceTypeId));

  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('Lender updated successfully');
};

const deleteLenderAction = (id) => async (dispatch) => {
  const res = await lendersApiCalls.deleteLender(id);
  if (!res) return null;

  const lenderId = res.data?.id;
  dispatch(deleteLender(lenderId));
  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('Lender deleted successfully');
};

const getLenderFilterDiapasonsAction = (financeTypeId) => async (dispatch) => {
  const params = { financeTypeId };
  const res = await lendersApiCalls.getLenderFilterDiapasons(params);
  if (!res) return null;
  const filterDiapasons = res.filterDiapasons;
  dispatch(setLenderFilterDiapasons(filterDiapasons));
};

const clearLenderFilterDiapasonsAction = () => (dispatch) => {
  dispatch(clearLenderFilterDiapasons());
};

const setFilteredLenderAction = (isReverse) => (dispatch) => {
  dispatch(setFilteredLender(isReverse));
};

export {
  clearLenderFilterDiapasonsAction,
  getLenderFilterDiapasonsAction,
  setFilteredLenderAction,
  getAllLendersAction,
  updateLenderAction,
  deleteLenderAction,
  addLenderAction,
};
