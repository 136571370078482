import cn from 'classnames';
import styles from './styles.module.scss';
import { capitalizeFirstLetter, toDivideNumber } from '../../helpers';

const setTitle = (key) => {
  switch (key) {
    case 'max_loan_to_value': {
      return 'Max Loan-To-Value';
    }
    case 'is_personal_guarantee': {
      return 'Personal Guarantee';
    }
    case 'is_early_repayment_charges': {
      return 'Early Repayment Charges';
    }
    case 'treatment_interest': {
      return 'Interest Treatment';
    }
    default: {
      const str = key.replace(/_/g, ' ');
      return getAllCapitalizedWords(str);
    }
  }
};

const getAllCapitalizedWords = (str) => {
  const arr = str.split(' ');
  let out = '';
  for (let i = 0; i < arr.length; i++) {
    const word = arr[i];
    if (i == arr.length - 1) {
      out += capitalizeFirstLetter(word);
    } else {
      out += capitalizeFirstLetter(word) + ' ';
    }
  }

  return out;
};

const getDataItem = (data) => {
  const copiedData = JSON.parse(JSON.stringify(data));
  delete copiedData.financeTypeId;
  delete copiedData.createdAt;
  delete copiedData.updatedAt;
  delete copiedData.image;
  delete copiedData.title;
  delete copiedData.id;

  const values = Object.values(copiedData);
  const keys = Object.keys(copiedData);

  const setSubtitle = (key, value) => {
    switch (true) {
      case /^(max|min)(_rate|_loan_to_value)$/.test(key): {
        return `${value} %`;
      }
      case /^(max|min)(_loan)$/.test(key): {
        return `£ ${toDivideNumber(value)}`;
      }
      case /^(max|min)(_fixed_rate_term|_term|_age)$/.test(key): {
        return `${value} year(s)`;
      }
      case /^(max|min)(_loan_processing_time)$/.test(key): {
        return `${value} day(s)`;
      }
      case /^(max|min)(_decision_time)$/.test(key): {
        return `${value} hour(s)`;
      }
      case /^(max|min)(_trading_period)$/.test(key): {
        return `${value} month(s)`;
      }
      default: {
        return value;
      }
    }
  };

  const items = [];
  const additionalInfoData = {};

  for (let i in keys) {
    const key = keys[i];
    const value = values[i];
    const title = setTitle(key);

    if (key === 'additional_info' && value) {
      /* for possibility add additional info to end of list */
      additionalInfoData.title = title;
      additionalInfoData.subtitle = setSubtitle(key, value);
      continue;
    }

    switch (true) {
      case typeof value === 'boolean': {
        const subtitle = value ? 'Yes' : 'No';
        items.push({ subtitle, title });
        break;
      }
      case Array.isArray(value): {
        const isStringValue = value.every((el) => typeof el === 'string');
        isStringValue && value.length && items.push({ subtitle: value.join(', '), title });
        break;
      }
      case typeof value === 'number': {
        value && items.push({ subtitle: setSubtitle(key, value), title });
        break;
      }
      case typeof value === 'string': {
        value !== '0.00' && value && items.push({ subtitle: setSubtitle(key, value), title });
        break;
      }
      default: {
        break;
      }
    }
  }

  Object.values(additionalInfoData).length && items.push(additionalInfoData);
  return items;
};

const LenderInfoModalContent = ({ data }) => {
  const items = getDataItem(data);

  return (
    <div className={styles['LenderInfoModalContent']}>
      <div className={styles['image-wrapper']}>
        {data.image && <img className={styles['image']} src={data.image} alt="LenderLogo" />}
      </div>

      <div className={styles['content-wrapper']}>
        <h2 className={styles['title']}>{data.title}</h2>
        <table className={styles['table']}>
          <tbody>
            {items.map(({ subtitle, title }, i) => {
              if (/^(\s+)?http/.test(subtitle)) {
                return (
                  <tr key={i}>
                    <td className={cn(styles['table-item'], styles['table-item-left'])}>{title}</td>
                    <td className={cn(styles['table-item'], styles['table-item-right'])}>
                      <a href={subtitle} target="blank" className={styles['table-item-link']}>
                        {subtitle}
                      </a>
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={i}>
                  <td className={cn(styles['table-item'], styles['table-item-left'])}>{title}</td>
                  <td className={cn(styles['table-item'], styles['table-item-right'])}>
                    {subtitle}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { LenderInfoModalContent };
