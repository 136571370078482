import styles from './styles.module.scss';
import SaveIcon from '@mui/icons-material/Save';
import { notificationService } from '../../services';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const InputFile = ({ setImage }) => {
  const fileHandler = async ({ target }) => {
    const file = target?.files[0];
    const size = target?.files[0]?.size;
    const imageBase64 = file && (await toBase64(file));
    /* 
    TEXT --	64 Kilobytes ||	65,535 characters
    MEDIUMTEXT	-- 16 Megabytes ||	16,777,215 characters
    LONGTEXT	-- 4 Gigabytes	|| 4,294,967,295 characters
    */
    if (imageBase64 && (size > 1000000 * 16 || imageBase64.length > 16777215)) {
      return notificationService.info('File size is too large');
    }

    setImage(imageBase64 || '');
  };

  return (
    <>
      <label htmlFor="js-input-file" className={styles['input-label']}>
        <SaveIcon color="inherit" />
      </label>
      <input id="js-input-file" className={styles['input']} type="file" onChange={fileHandler} />
    </>
  );
};

export { InputFile };
