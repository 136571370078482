import { Navigate } from 'react-router-dom';
import { ROLE_NAME_ADMIN } from '../common/constants';

const ProtectedAdminRoute = ({ user, redirectPath = '/login', children }) => {
  if (!user || user.role !== ROLE_NAME_ADMIN) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export { ProtectedAdminRoute };
