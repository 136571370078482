import { financeTypesApiCalls } from './apiCalls';
import { notificationService } from '../../services';
import { setModalContent } from '../settings/actionCreators';
import {
  updateFinanceType,
  deleteFinanceType,
  saveFinanceTypes,
  saveFinanceType,
} from './actionCreators';

const getAllFinanceTypesAction = () => async (dispatch) => {
  const res = await financeTypesApiCalls.getAllFinanceTypes();
  if (!res) return null;

  const items = res.items;
  dispatch(saveFinanceTypes(items));
};

const addFinanceTypeAction = (data) => async (dispatch) => {
  const res = await financeTypesApiCalls.addFinanceType(data);
  if (!res) return null;

  const financeType = res.data?.financeType;
  dispatch(saveFinanceType(financeType));
  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('Finance type added successfully');
};

const updateFinanceTypeAction = (data) => async (dispatch) => {
  const res = await financeTypesApiCalls.updateFinanceType(data);
  if (!res) return null;

  const financeType = res.data?.financeType;
  dispatch(updateFinanceType(financeType));
  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('Finance type updated successfully');
};

const deleteFinanceTypeAction = (id) => async (dispatch) => {
  const res = await financeTypesApiCalls.deleteFinanceType(id);
  if (!res) return null;

  const financeTypeId = res.data?.id;
  dispatch(deleteFinanceType(financeTypeId));
  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('Finance type deleted successfully');
};

export {
  getAllFinanceTypesAction,
  updateFinanceTypeAction,
  deleteFinanceTypeAction,
  addFinanceTypeAction,
};
