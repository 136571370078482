import axios from 'axios';
import { notificationService } from './';
import { localStoreService } from '../services';

const DEFAULT_HEADERS = { 'Content-Type': 'application/json' };
// const MAIN_URL = 'http://localhost:9070/v1';
const MAIN_URL = '/v1';
class HttpService {
  async request(incomingUrl, method, headers = DEFAULT_HEADERS, params = {}, body) {
    const url = `${MAIN_URL}${incomingUrl}`;

    // Add a request interceptor
    axios.interceptors.request.use(
      // Do something before request is sent
      function (config) {
        /* token authorization for all requests except auth requests */
        if (!/\/auth/.test(incomingUrl)) {
          const token = localStoreService.get('token');
          config.headers = {
            ...config.headers,
            ...headers,
            Authorization: `Bearer ${token}`,
          };
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    const config = {
      ...params,
      url,
      method: method,
      data: body,
    };

    try {
      return await axios(config);
    } catch (error) {
      let errorObj = { message: 'Unknown error', status: 500 };
      if (error.response) {
        errorObj = {
          ...errorObj,
          message:
            error.response.data.message || error.response.statusText || error.response.data.error,
          status: error.response.status,
        };
      } else {
        // Something happened in setting up the request that triggered an Error
        errorObj = { ...errorObj, message: error.message, status: 400 };
      }

      notificationService.error(errorObj.message);
    }
  }

  get({ url, headers = {}, params = {} }) {
    return this.request(url, 'GET', headers, params);
  }

  delete({ url, headers = {}, params = {} }) {
    return this.request(url, 'DELETE', headers, params);
  }

  patch({ url, headers = {}, params = {}, body }) {
    return this.request(url, 'PUT', headers, params, body);
  }

  post({ url, headers = {}, params = {}, body }) {
    return this.request(url, 'POST', headers, params, body);
  }

  parseResponse(res) {
    if (!res) return null;

    const { data } = res;

    return data;
  }
}

const httpService = new HttpService();

export { httpService };
