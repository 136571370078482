import { authReducer } from './auth/reducer';
import { usersReducer } from './users/reducer';
import { combineReducers } from '@reduxjs/toolkit';
import { lendersReducer } from './lenders/reducer';
import { settingsReducer } from './settings/reducer';
import { financeTypesReducer } from './finance-types/reducer';

const rootReducer = combineReducers({
  financeTypes: financeTypesReducer,
  settings: settingsReducer,
  lenders: lendersReducer,
  users: usersReducer,
  auth: authReducer,
});

export { rootReducer };
