class LocalStoreService {
  constructor(store) {
    this.store = store;
  }

  get(key) {
    return this.store.getItem(key);
  }

  set(key, value) {
    return this.store.setItem(key, value);
  }

  delete(key) {
    return this.store.removeItem(key);
  }
}

const localStoreService = new LocalStoreService(localStorage);

export { localStoreService };
