import {
  CLEAR_LENDER_FILTER_DIAPASONS,
  SET_LENDER_FILTER_DIAPASONS,
  SET_FILTERED_LENDER,
  UPDATE_LENDER,
  DELETE_LENDER,
  SAVE_LENDERS,
  SAVE_LENDER,
} from './actionTypes';

const initialState = {
  lenders: [],
  filterDiapasons: {},
};

const lendersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LENDER_FILTER_DIAPASONS: {
      const { filterDiapasons } = action.payload;
      return {
        ...state,
        filterDiapasons,
      };
    }
    case SET_FILTERED_LENDER: {
      const { isReverse } = action.payload;
      const compare = (a, b) => {
        if (a.title[0].toLowerCase() < b.title[0].toLowerCase()) {
          return -1;
        }
        if (a.title[0].toLowerCase() > b.title[0].toLowerCase()) {
          return 1;
        }
        return 0;
      };

      const copyLenders = [...state.lenders];
      const items = copyLenders.sort(compare);
      isReverse && items.reverse();

      return {
        ...state,
        lenders: items,
      };
    }
    case SAVE_LENDERS: {
      const { items } = action.payload;
      return {
        ...state,
        lenders: items,
      };
    }
    case SAVE_LENDER: {
      const { lender } = action.payload;

      return {
        ...state,
        lenders: [...state.lenders, lender],
      };
    }
    case UPDATE_LENDER: {
      const { lender } = action.payload;
      const updatedStateItems = [...state.lenders].map((stateLender) =>
        stateLender.id === lender.id ? lender : stateLender
      );

      return {
        ...state,
        lenders: updatedStateItems,
      };
    }

    case CLEAR_LENDER_FILTER_DIAPASONS: {
      return {
        ...state,
        filterDiapasons: {},
      };
    }

    case DELETE_LENDER: {
      const { id } = action.payload;
      return {
        ...state,
        lenders: [...state.lenders].filter((stateLender) => stateLender.id !== id),
      };
    }
    default: {
      return { ...state };
    }
  }
};

export { lendersReducer };
