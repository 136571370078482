import styles from './styles.module.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from '../../components';
import { notificationService } from '../../services';

const Login = ({ isDarkMode, loginAction }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const inputHandler = ({ target: { value, name } }) => {
    name === 'password' && setPassword(value);
    name === 'username' && setUsername(value);
  };

  const onSubmit = async () => {
    if (!username || !password) {
      return notificationService.error('All fields are required');
    }

    const data = { username, password };
    const user = await loginAction(data);
    user && navigate('/finance-types');
  };

  return (
    <div className={styles['Login']}>
      <div className={styles['form']}>
        <div className={styles['input-wrapper']}>
          <Input
            label="Username"
            name="username"
            isDarkMode={isDarkMode}
            value={username}
            onChange={inputHandler}
          />
        </div>

        <div className={styles['input-wrapper']}>
          <Input
            label="Password"
            name="password"
            type="password"
            isDarkMode={isDarkMode}
            value={password}
            onChange={inputHandler}
          />
        </div>

        <div className={styles['btn-wrapper']}>
          <Button onClick={onSubmit}>Login</Button>
        </div>
      </div>
    </div>
  );
};

export { Login };
