import { usersApiCalls } from './apiCalls';
import { notificationService } from '../../services';
import { setModalContent } from '../settings/actionCreators';
import { updateUser, deleteUser, saveUsers, saveUser } from './actionCreators';

const getAllUsersAction = () => async (dispatch) => {
  const res = await usersApiCalls.getAllUsers();
  if (!res) return null;

  const items = res.items;
  dispatch(saveUsers(items));
};

const addUserAction = (data) => async (dispatch) => {
  const res = await usersApiCalls.addUser(data);
  if (!res) return null;

  const user = res.data?.user;
  dispatch(saveUser(user));
  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('User added successfully');
};

const updateUserAction = (data) => async (dispatch) => {
  const res = await usersApiCalls.updateUser(data);
  if (!res) return null;

  const user = res.data?.user;
  dispatch(updateUser(user));
  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('User updated successfully');
};

const deleteUserAction = (id) => async (dispatch) => {
  const res = await usersApiCalls.deleteUser(id);
  if (!res) return null;

  const userId = res.data?.id;
  dispatch(deleteUser(userId));
  /* close modal */
  dispatch(setModalContent(null));
  notificationService.success('User deleted successfully');
};

export { getAllUsersAction, updateUserAction, deleteUserAction, addUserAction };
