import React from 'react';
import styles from './styles.module.scss';
import Tooltip from '@mui/material/Tooltip';
import MateriaSlider from '@mui/material/Slider';
import { toDivideNumber } from '../../helpers';

const setStep = (params) => {
  const values = Object.values(params);
  let isBigValue = false;
  let isFloat = false;

  values.forEach((value) => {
    value > 1000 && (isBigValue = true);
    !(value % 1) && (isFloat = true);
  });

  if (isBigValue) {
    return 10000;
  }

  return isFloat ? 0.5 : 1;
};

const setDiapason = (min, max) => {
  if (min < max) {
    return { min, max };
  }

  return { min: max, max: min };
};

const ValueLabelComponent = ({ children, value }) => {
  return (
    <Tooltip enterTouchDelay={0} placement="top" title={toDivideNumber(value)} arrow>
      {children}
    </Tooltip>
  );
};

const Slider = ({ isDisabled = false, value, name, min, max, onChange }) => {
  const minValue = setDiapason(min, max).min;
  const maxValue = setDiapason(min, max).max;
  const step = setStep([min, max]);

  return (
    <MateriaSlider
      className={styles['Slider']}
      disabled={isDisabled}
      value={value}
      name={name}
      step={setStep([min, max])}
      min={minValue}
      max={maxValue}
      onChange={onChange}
      valueLabelDisplay={step > 1 ? 'auto' : 'on'}
      slots={{
        [step > 1 && 'valueLabel']: ValueLabelComponent,
      }}
    />
  );
};

export { Slider };
