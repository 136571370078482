import cn from 'classnames';
import styles from './styles.module.scss';
import MaterialModal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ isDarkMode, content, setModalContentAction }) => {
  const onClose = () => {
    setModalContentAction(null);
  };

  return (
    <MaterialModal open={Boolean(content)} onClose={onClose}>
      <div className={cn(styles['Modal'], { [styles['Modal--dark']]: isDarkMode })}>
        <div className={styles['content-wrapper']}>
          <span className={styles['btn-wrapper']} onClick={onClose}>
            <CloseIcon color="inherit" />
          </span>
          {content}
        </div>
      </div>
    </MaterialModal>
  );
};

export { Modal };
