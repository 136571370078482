import { Login } from './Login';
import { connect } from 'react-redux';
import { loginAction } from '../../redux/auth/actions';

const mapStateToProps = (state) => ({
  isDarkMode: state.settings.isDarkMode,
});

const mapDispatchToProps = { loginAction };

const ReduxLogin = connect(mapStateToProps, mapDispatchToProps)(Login);

export { ReduxLogin as Login };
