import { ApiCallService } from '../services';

class AuthApiCalls extends ApiCallService {
  constructor(apiUrl) {
    super(apiUrl);
  }

  async validateToken(token) {
    const url = '/validate-token';
    const data = { token };
    return this.send(url, data);
  }
}

const apiUrl = '/auth';

const authApiCalls = new AuthApiCalls(apiUrl);

export { authApiCalls };
