import { authApiCalls } from './apiCalls';
import { decodeToken } from '@leteu/jwt-decoder';
import { localStoreService } from '../../services';
import { saveUser, logOut } from './actionCreators';

const loginAction = (data) => async (dispatch) => {
  const res = await authApiCalls.login(data);
  if (!res) return null;

  const token = res.data?.token;
  const decode = decodeToken(token);
  const user = decode.data;

  dispatch(saveUser(user));
  localStoreService.set('token', token);

  return user;
};

const saveUserAction = (user) => async (dispatch) => {
  dispatch(saveUser(user));
};

const logOutAction = () => async (dispatch) => {
  dispatch(logOut());
  localStoreService.delete('token');
};

export { saveUserAction, loginAction, logOutAction };
