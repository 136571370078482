import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';

const storeConfiguration = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }).concat(thunk),
    ],
  });

  return store;
};

export { storeConfiguration };
