import {
  SET_DARK_MODE_STATE,
  SET_LOADING_STATE,
  SET_MODAL_CONTENT,
  SET_DRAWER_STATE,
} from './actionTypes';

const setLoadingState = (isLoading) => ({ type: SET_LOADING_STATE, payload: { isLoading } });

const setDrawerState = (isDrawerOpen) => ({ type: SET_DRAWER_STATE, payload: { isDrawerOpen } });

const setDarkMode = (isDarkMode) => ({ type: SET_DARK_MODE_STATE, payload: { isDarkMode } });

const setModalContent = (content) => ({ type: SET_MODAL_CONTENT, payload: { content } });

export { setLoadingState, setDrawerState, setModalContent, setDarkMode };
