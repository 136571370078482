import styles from './styles.module.scss';
import { Breadcrumbs } from '../';
import { NavLink } from 'react-router-dom';
import { ROLE_NAME_ADMIN } from '../../common/constants';

const Nav = ({ pathname, userRole, queryParams = {} }) => {
  const isUsersPath = pathname === '/users';
  return (
    <div className={styles['Nav']}>
      <Breadcrumbs pathname={pathname} queryParams={queryParams} />
      {userRole === ROLE_NAME_ADMIN && (
        <NavLink to="/users" className={styles['link']}>
          users
        </NavLink>
      )}
      {isUsersPath && (
        <NavLink to="/finance-types" className={styles['link']}>
          finance-types
        </NavLink>
      )}
    </div>
  );
};

export { Nav };
