import styles from './styles.module.scss';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

const AddItem = ({ handler }) => {
  return (
    <div className={styles['AddItem']}>
      <div className={styles['add-btn-wrapper']}>
        <IconButton color="inherit" component="label" onClick={handler}>
          <AddIcon color="inherit" fontSize="large" />
        </IconButton>
      </div>
    </div>
  );
};

export { AddItem };
