import cn from 'classnames';
import styles from './styles.module.scss';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import LightModeSharpIcon from '@mui/icons-material/LightModeSharp';
import NightlightSharpIcon from '@mui/icons-material/NightlightSharp';
import { useLocation } from 'react-router-dom';
import { localStoreService } from '../../services';

const Header = ({ isDarkMode, setDarkModeAction, setDrawerStateAction, logOutAction }) => {
  const { pathname } = useLocation();
  const isShowMenuBtn = pathname === '/finance-types' || pathname === '/finance-types/lenders';
  const isShowLogoutBtn = pathname !== '/login';

  const modeHandler = () => {
    setDarkModeAction(!isDarkMode);
  };

  const drawerHandler = () => {
    setDrawerStateAction(true);
  };

  const logOut = () => {
    logOutAction();
    localStoreService.delete('token');
  };

  return (
    <div
      className={cn(styles['Header'], {
        [styles['Header--dark']]: isDarkMode,
      })}
    >
      <div className="container">
        <header className={styles['content-wrapper']}>
          <div className={styles['left-content']}>
            {isShowMenuBtn && (
              <div
                className={cn(styles['menu-btn-wrapper'], {
                  [styles['menu-btn-wrapper--dark']]: isDarkMode,
                })}
              >
                <IconButton color="inherit" component="label" onClick={drawerHandler}>
                  <MenuSharpIcon color="inherit" fontSize="large" />
                </IconButton>
              </div>
            )}

            <img className={styles['logo']} src="/logo1.png" alt="logo" />
          </div>
          <div className={styles['right-content']}>
            <div className={styles['mode-wrapper']}>
              <IconButton color="inherit" component="label" onClick={modeHandler}>
                {isDarkMode ? (
                  <LightModeSharpIcon color="inherit" />
                ) : (
                  <NightlightSharpIcon color="inherit" />
                )}
              </IconButton>
            </div>

            {isShowLogoutBtn && (
              <div className={styles['logout-wrapper']}>
                <IconButton color="inherit" component="label" onClick={logOut}>
                  <LogoutIcon color="inherit" />
                </IconButton>
              </div>
            )}
          </div>
        </header>
      </div>
    </div>
  );
};

export { Header };
