import queryString from 'query-string';
import { ApiCallService } from '../../services';

class LendersApiCalls extends ApiCallService {
  constructor(apiUrl) {
    super(apiUrl);
  }

  async getAllLenders(params) {
    const stringified = queryString.stringify(params);
    const url = `/?${stringified}`;
    return this.get(url);
  }

  async addLender(data) {
    const url = '/';
    return this.send(url, data);
  }

  async updateLender(data) {
    const url = '/';
    return this.update(url, data);
  }

  async deleteLender(id) {
    const url = `/${id}`;
    return this.delete(url);
  }

  async getLenderFilterDiapasons(params) {
    const stringified = queryString.stringify(params);
    const url = `/filter-diapasons?${stringified}`;
    return this.get(url);
  }
}

const apiUrl = '/lender';

const lendersApiCalls = new LendersApiCalls(apiUrl);

export { lendersApiCalls };
