import styles from './styles.module.scss';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const CssTextField = styled((props) => <TextField {...props} />)((props) => ({
  '.MuiSelect-icon': {
    color: '#39B6FD',
  },
  '& label.Mui-focused': {
    color: props['data-mode'] ? '#39B6FD' : '#606770',
  },
  '& label': {
    color: props['data-mode'] ? '#39B6FD' : '#606770',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#39B6FD',
  },
  '& .MuiOutlinedInput-root': {
    borderWidth: 1.5,
    color: props['data-mode'] ? '#ecf4f9' : '#252627',

    '& fieldset': {
      borderColor: '#39B6FD',
    },
    '&:hover fieldset': {
      borderColor: '#39B6FD',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#39B6FD',
      borderWidth: 3,
    },
  },
}));

const Input = ({
  isDisabled = false,
  isDarkMode,
  value,
  label,
  name,
  rows,
  type = 'text',
  selectItems = [],
  defaultValue = '',
  onChange,
  onBlur,
}) => {
  const isSelect = Boolean(selectItems.length);
  const inputProps = {
    select: isSelect ? true : false,
    multiline: rows ? true : false,
    [isSelect && 'defaultValue']: defaultValue,
    [rows && 'rows']: rows,
  };

  return (
    <CssTextField
      value={value}
      label={label}
      name={name}
      type={type}
      data-mode={isDarkMode}
      className={styles['Input']}
      onChange={onChange}
      onBlur={onBlur}
      {...inputProps}
    >
      {selectItems.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CssTextField>
  );
};

export { Input };
