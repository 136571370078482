const ROLE_NAME_ADMIN = 'admin';
const ROLE_NAME_USER = 'user';

/* lender location covered */
const COVERED_LOCATION_NORTHERN_IRELAND = 'Northern Ireland';
const COVERED_LOCATION_SCOTLAND = 'Scotland';
const COVERED_LOCATION_ENGLAND = 'England';
const COVERED_LOCATION_WALES = 'Wales';

const COVERED_LOCATION_ITEMS = [
  COVERED_LOCATION_NORTHERN_IRELAND,
  COVERED_LOCATION_SCOTLAND,
  COVERED_LOCATION_ENGLAND,
  COVERED_LOCATION_WALES,
];

/* lender interest treatment */
const TREATMENT_INTEREST_INTEREST_ONLY = 'Interest only';
const TREATMENT_INTEREST_ROLLED_UP = 'Rolled up';
const TREATMENT_INTEREST_REPAYMENT = 'Repayment';
const TREATMENT_INTEREST_RETAINED = 'Retained';
const TREATMENT_INTEREST_SERVICED = 'Serviced';

const TREATMENT_INTEREST_ITEMS = [
  TREATMENT_INTEREST_INTEREST_ONLY,
  TREATMENT_INTEREST_ROLLED_UP,
  TREATMENT_INTEREST_REPAYMENT,
  TREATMENT_INTEREST_RETAINED,
  TREATMENT_INTEREST_SERVICED,
];

/* lender valuation type */
const VALUATION_TYPE_RED_BOOK = 'Red book';
const VALUATION_TYPE_DESKTOP = 'Desktop';

const VALUATION_TYPE_ITEMS = [VALUATION_TYPE_RED_BOOK, VALUATION_TYPE_DESKTOP];

/* lender applicant type */
const APPLICANT_TYPE_SHARIA_COMPLIANT = 'SHARIA COMPLIANT';
const APPLICANT_TYPE_PARTNERSHIP = 'PARTNERSHIP';
const APPLICANT_TYPE_INDIVIDUAL = 'INDIVIDUAL';
const APPLICANT_TYPE_COMPLEX = 'COMPLEX';
const APPLICANT_TYPE_EX_PATS = 'EX-PATS';
const APPLICANT_TYPE_SIPPS = 'SIPPS';
const APPLICANT_TYPE_TRUST = 'TRUST';
const APPLICANT_TYPE_LLP = 'LLP';
const APPLICANT_TYPE_PLC = 'PLC';
const APPLICANT_TYPE_LTD = 'LTD';

const APPLICANT_TYPE_ITEMS = [
  APPLICANT_TYPE_SHARIA_COMPLIANT,
  APPLICANT_TYPE_PARTNERSHIP,
  APPLICANT_TYPE_INDIVIDUAL,
  APPLICANT_TYPE_COMPLEX,
  APPLICANT_TYPE_EX_PATS,
  APPLICANT_TYPE_SIPPS,
  APPLICANT_TYPE_TRUST,
  APPLICANT_TYPE_LLP,
  APPLICANT_TYPE_PLC,
  APPLICANT_TYPE_LTD,
];

/* lender experience type */
const EXPERIENCE_TYPE_EXPERIENCED = 'Experienced';
const EXPERIENCE_TYPE_FIRST_TIME = 'First time';
const EXPERIENCE_TYPE_FLEXIBLE = 'Flexible';

const EXPERIENCE_TYPE_ITEMS = [
  EXPERIENCE_TYPE_EXPERIENCED,
  EXPERIENCE_TYPE_FIRST_TIME,
  EXPERIENCE_TYPE_FLEXIBLE,
];

const GROUP_MULTIPLE_VALUE_ITEMS = [
  { items: TREATMENT_INTEREST_ITEMS, dataKey: 'treatment_interest', title: 'Interest Treatment' },
  { items: COVERED_LOCATION_ITEMS, dataKey: 'covered_location', title: 'Location Covered' },
  { items: EXPERIENCE_TYPE_ITEMS, dataKey: 'experience_type', title: 'Experience Type' },
  { items: VALUATION_TYPE_ITEMS, dataKey: 'valuation_type', title: 'Valuation Type' },
];
const BOOLEAN_DATA_ITEMS = [
  { key: 'is_early_repayment_charges', title: 'Early repayment charges' },
  { key: 'is_personal_guarantee', title: 'Personal Guarantee' },
  { key: 'is_owner_occupant', title: 'Owner occupant' },
  { key: 'is_interest_only', title: 'Interest Only' },
];

export {
  GROUP_MULTIPLE_VALUE_ITEMS,
  TREATMENT_INTEREST_ITEMS,
  COVERED_LOCATION_ITEMS,
  EXPERIENCE_TYPE_ITEMS,
  VALUATION_TYPE_ITEMS,
  BOOLEAN_DATA_ITEMS,
  ROLE_NAME_ADMIN,
  ROLE_NAME_USER,
};
