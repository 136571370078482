import styles from './styles.module.scss';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '../../components';
import { ROLE_NAME_ADMIN } from '../../common/constants';

const FinanceTypeItem = ({ /* isDarkMode */ dataContent = {}, userRole, handler, onEdit }) => {
  const { description, title, image, id } = dataContent;

  const editHandler = () => {
    onEdit();
  };

  return (
    <div className={styles['FinanceTypeItem']}>
      <div className={styles['image-wrapper']}>
        {image && <img className={styles['image']} src={image} alt="FinanceTypeLogo" />}
      </div>

      {userRole === ROLE_NAME_ADMIN && (
        <div className={styles['edit-btn-wrapper']}>
          <IconButton color="inherit" component="label" onClick={editHandler}>
            <MoreVertIcon color="inherit" />
          </IconButton>
        </div>
      )}

      <h2 className={styles['title']}>{title}</h2>
      <div className={styles['description']}>{description}</div>
      <div className={styles['btn-wrapper']}>
        <Button
          // isDarkMode={isDarkMode}
          onClick={() => {
            handler(Number(id));
          }}
        >
          learn more
        </Button>
      </div>
    </div>
  );
};

export { FinanceTypeItem };
