import { Login } from '../views/login';
import { Users } from '../views/users';
import { Lenders } from '../views/lenders';
import { authApiCalls } from '../api-calls';
import { Loader, Modal } from '../components';
import { localStoreService } from '../services';
import { ProtectedRoute } from './ProtectedRoute';
import { useLayoutEffect, useEffect } from 'react';
import { FinanceTypes } from '../views/finance-types';
import { ProtectedAdminRoute } from './ProtectedAdminRoute';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';

let timeoutRedirect = null;

const Router = ({
  isDarkMode,
  isLoading,
  modalContent,
  user,
  setLoadingStateAction,
  setModalContentAction,
  saveUserAction,
}) => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const fetchData = async () => {
      if (!user) {
        const token = localStoreService.get('token');

        if (token) {
          const data = await authApiCalls.validateToken(token);
          const user = data?.data?.user;
          user && saveUserAction(user);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearTimeout(timeoutRedirect);
    /* start loading */
    setLoadingStateAction(true);
    if (user) {
      navigate('/finance-types');
    }

    timeoutRedirect = setTimeout(async () => {
      /* stop loading */
      setLoadingStateAction(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Loader isDarkMode={isDarkMode} isLoading={isLoading} />
      <Modal
        isDarkMode={isDarkMode}
        content={modalContent}
        setModalContentAction={setModalContentAction}
      />
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/finance-types"
          element={
            <ProtectedRoute user={user}>
              <FinanceTypes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/finance-types/lenders"
          element={
            <ProtectedRoute user={user}>
              <Lenders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedAdminRoute user={user}>
              <Users />
            </ProtectedAdminRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export { Router };
