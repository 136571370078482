import { ApiCallService } from '../../services';

class AuthApiCalls extends ApiCallService {
  constructor(apiUrl) {
    super(apiUrl);
  }

  async login(data) {
    const url = '/';
    return this.send(url, data);
  }
}

const apiUrl = '/auth';

const authApiCalls = new AuthApiCalls(apiUrl);

export { authApiCalls };
