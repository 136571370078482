import { ApiCallService } from '../../services';

class FinanceTypesApiCalls extends ApiCallService {
  constructor(apiUrl) {
    super(apiUrl);
  }

  async getAllFinanceTypes() {
    const url = '/';
    return this.get(url);
  }

  async addFinanceType(data) {
    const url = '/';
    return this.send(url, data);
  }

  async updateFinanceType(data) {
    const url = '/';
    return this.update(url, data);
  }

  async deleteFinanceType(id) {
    const url = `/${id}`;
    return this.delete(url);
  }
}

const apiUrl = '/finance-type';

const financeTypesApiCalls = new FinanceTypesApiCalls(apiUrl);

export { financeTypesApiCalls };
