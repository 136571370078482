import styles from './styles.module.scss';
import { useState } from 'react';
import { notificationService } from '../../services';
import { ROLE_NAME_USER } from '../../common/constants';
import { FinanceTypeItem, InputFile, Button, Input } from '../../components';

const AddEditFinanceTypeModalContent = ({
  isDarkMode,
  dataContent = {},
  updateFinanceTypeAction,
  deleteFinanceTypeAction,
  addFinanceTypeAction,
}) => {
  const [image, setImage] = useState(dataContent.image || '');
  const [title, setTitle] = useState(dataContent.title || '');
  const [description, setDescription] = useState(dataContent.description || '');
  const isEdit = Boolean(dataContent.id); // if dataContent has id -> is edit mode

  const financeTypeHandler = () => {
    if (!description || !title || !image) {
      return notificationService.error('All fields are required');
    }

    const data = { description, title, image, id: dataContent.id };
    isEdit ? updateFinanceTypeAction(data) : addFinanceTypeAction(data);
  };

  const inputHandler = ({ target: { value, name } }) => {
    name === 'title' && setTitle(value);
    name === 'description' && setDescription(value);
  };

  const deleteFinanceType = () => {
    const id = dataContent.id;
    deleteFinanceTypeAction(id);
  };

  return (
    <div className={styles['AddEditFinanceTypeModalContent']}>
      <div className={styles['content']}>
        <div className={styles['input-wrapper']}>
          <InputFile setImage={setImage} />
        </div>
        <div className={styles['input-wrapper']}>
          <Input
            label="Title"
            name="title"
            isDarkMode={isDarkMode}
            value={title}
            onChange={inputHandler}
          />
        </div>
        <div className={styles['input-wrapper']}>
          <Input
            label="Description"
            name="description"
            isDarkMode={isDarkMode}
            value={description}
            rows={6}
            onChange={inputHandler}
          />
        </div>

        <div className={styles['btn-wrapper']}>
          <Button onClick={financeTypeHandler}>
            {isEdit ? 'Edit finance type' : 'Add finance type'}
          </Button>
        </div>

        {isEdit && (
          <div className={styles['delete-btn-wrapper']}>
            <Button isError onClick={deleteFinanceType}>
              Delete finance type
            </Button>
          </div>
        )}
      </div>

      <div className={styles['preview']}>
        <FinanceTypeItem
          dataContent={{
            description,
            image,
            title,
          }}
          userRole={ROLE_NAME_USER} // without edit tools
          handler={() => {
            /* without handler on MORE button */
          }}
        />
      </div>
    </div>
  );
};

export { AddEditFinanceTypeModalContent };
