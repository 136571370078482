import { httpService } from '.';

class ApiCallService {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  async get(url) {
    const response = await httpService.get({
      url: `${this.apiUrl}${url}`,
    });

    return httpService.parseResponse(response);
  }

  async send(url, data) {
    const response = await httpService.post({
      url: `${this.apiUrl}${url}`,
      body: data,
    });

    return httpService.parseResponse(response);
  }

  async update(url, data) {
    const response = await httpService.patch({
      url: `${this.apiUrl}${url}`,
      body: data,
    });

    return httpService.parseResponse(response);
  }

  async delete(url) {
    const response = await httpService.delete({
      url: `${this.apiUrl}${url}`,
    });

    return httpService.parseResponse(response);
  }
}

export { ApiCallService };
