import styles from './styles.module.scss';
import Typography from '@mui/material/Typography';
import MaterialAccordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const Accordion = ({ children, isExpanded = false, title }) => {
  return (
    <MaterialAccordion {...(isExpanded && { expanded: true })} className={styles['Accordion']}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={styles['more-icon']} />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles['content-wrapper']}>{children}</div>
      </AccordionDetails>
    </MaterialAccordion>
  );
};

export { Accordion };
