import { Users } from './Users';
import { connect } from 'react-redux';
import { setModalContentAction } from '../../redux/settings/actions';
import {
  getAllUsersAction,
  updateUserAction,
  deleteUserAction,
  addUserAction,
} from '../../redux/users/actions';

const mapStateToProps = (state) => ({
  isDarkMode: state.settings.isDarkMode,
  users: state.users.users,
});

const mapDispatchToProps = {
  setModalContentAction,
  getAllUsersAction,
  updateUserAction,
  deleteUserAction,
  addUserAction,
};

const ReduxUsers = connect(mapStateToProps, mapDispatchToProps)(Users);

export { ReduxUsers as Users };
