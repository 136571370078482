import cn from 'classnames';
import styles from './styles.module.scss';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({ isDarkMode, isLoading }) => {
  return (
    <>
      {isLoading && (
        <div
          className={cn(styles['Loader'], {
            [styles['Loader--dark']]: isDarkMode,
          })}
        >
          <CircularProgress size={200} color="inherit" />
        </div>
      )}
    </>
  );
};

export { Loader };
