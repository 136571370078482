import cn from 'classnames';
import styles from './styles.module.scss';
import { Header } from './header';
import { Footer } from './footer';
import { Drawer } from '../components';
import { useLocation } from 'react-router-dom';

const Layout = ({
  filterDiapasons,
  financeTypes,
  isDrawerOpen,
  isDarkMode,
  children,
  clearLenderFilterDiapasonsAction,
  getLenderFilterDiapasonsAction,
  setDrawerStateAction,
  getAllLendersAction,
  setDarkModeAction,
  logOutAction,
}) => {
  const { pathname } = useLocation();
  const isShowSidebar = pathname === '/finance-types' || pathname === '/finance-types/lenders';

  return (
    <div
      className={cn(styles['Layout'], {
        [styles['Layout--dark']]: isDarkMode,
      })}
    >
      <Header
        isDarkMode={isDarkMode}
        setDrawerStateAction={setDrawerStateAction}
        setDarkModeAction={setDarkModeAction}
        logOutAction={logOutAction}
      />

      <div className={styles['wrapper']}>
        <div className="container">
          <div className={styles['content-wrapper']}>
            {isShowSidebar && (
              <div className={styles['sidebar-wrapper']}>
                <Drawer
                  filterDiapasons={filterDiapasons}
                  financeTypes={financeTypes}
                  isDrawerOpen={isDrawerOpen}
                  isDarkMode={isDarkMode}
                  clearLenderFilterDiapasonsAction={clearLenderFilterDiapasonsAction}
                  getLenderFilterDiapasonsAction={getLenderFilterDiapasonsAction}
                  setDrawerStateAction={setDrawerStateAction}
                  getAllLendersAction={getAllLendersAction}
                />
              </div>
            )}
            <main
              className={cn(styles['main'], {
                [styles['main--dark']]: isDarkMode,
              })}
            >
              {children}
            </main>
          </div>
        </div>
      </div>
      <Footer isDarkMode={isDarkMode} />
    </div>
  );
};

export { Layout };
