/* eslint-disable no-debugger */
import cn from 'classnames';
import styles from './styles.module.scss';
import { useState } from 'react';
import { ROLE_NAME_ADMIN } from '../../common/constants';
import { LenderItem, AddItem, Nav } from '../../components';
import { useSearchParams, useLocation } from 'react-router-dom';
import { AddEditLenderModalContent, LenderInfoModalContent } from '../../modal-contents';

const Lenders = ({
  financeTypes,
  isDarkMode,
  lenders,
  user,
  getAllFinanceTypesAction,
  setFilteredLenderAction,
  setModalContentAction,
  updateLenderAction,
  deleteLenderAction,
  addLenderAction,
}) => {
  const [isFilterByNameReverse, setFilterByNameReverse] = useState(false);
  const [isFilterByNameActive, setFilterByNameActive] = useState(false);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const financeTypeId = searchParams.get('financeTypeId');

  const getFinanceTypeTitle = () => {
    const financeTypeData = financeTypes.find(({ id }) => id === Number(financeTypeId));
    return financeTypeData?.title || '';
  };

  const itemHandler = (data) => {
    setModalContentAction(<LenderInfoModalContent data={data} />);
  };

  const addItemHandler = () => {
    const dataContent = { financeTypeIds: [financeTypeId] };
    setModalContentAction(
      <AddEditLenderModalContent
        financeTypeId={financeTypeId}
        financeTypes={financeTypes}
        dataContent={dataContent}
        isDarkMode={isDarkMode}
        getAllFinanceTypesAction={getAllFinanceTypesAction}
        updateLenderAction={updateLenderAction}
        deleteLenderAction={deleteLenderAction}
        addLenderAction={addLenderAction}
      />
    );
  };

  const editItemHandler = (data) => {
    const dataContent = { ...data, financeTypeIds: [financeTypeId] };
    setModalContentAction(
      <AddEditLenderModalContent
        financeTypeId={financeTypeId}
        financeTypes={financeTypes}
        dataContent={dataContent}
        isDarkMode={isDarkMode}
        getAllFinanceTypesAction={getAllFinanceTypesAction}
        updateLenderAction={updateLenderAction}
        deleteLenderAction={deleteLenderAction}
        addLenderAction={addLenderAction}
      />
    );
  };

  const filterByNameHandler = () => {
    if (isFilterByNameActive) {
      setFilterByNameReverse(!isFilterByNameReverse);
      setFilteredLenderAction(!isFilterByNameReverse);
    } else {
      setFilterByNameActive(true);
      setFilteredLenderAction(isFilterByNameReverse);
    }
  };

  return (
    <section className={styles['Lenders']}>
      <Nav pathname={pathname} userRole={user.role} />

      <h2 className={styles['title']}>{getFinanceTypeTitle()}</h2>
      {lenders.length ? (
        <div className={styles['filter']}>
          <span className={styles['filter-item']} onClick={filterByNameHandler}>
            name
            <span
              className={cn(styles['filter-name-arrow'], {
                [styles['filter-name-arrow--rotate']]: isFilterByNameReverse,
              })}
            >
              &#8593;
            </span>
          </span>
        </div>
      ) : (
        <></>
      )}

      <ul className={styles['content']}>
        {lenders.map((item, i) => (
          <li key={i} className={styles['content-lender-item']}>
            <LenderItem
              dataContent={item}
              userRole={user.role}
              handler={itemHandler}
              onEdit={() => editItemHandler(item)}
            />
          </li>
        ))}

        {user.role === ROLE_NAME_ADMIN && (
          <li className={styles['content-lender-item']}>
            <AddItem handler={addItemHandler} />
          </li>
        )}
      </ul>
    </section>
  );
};

export { Lenders };
