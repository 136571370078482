import cn from 'classnames';
import styles from './styles.module.scss';

const Footer = ({ isDarkMode }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={cn(styles['Footer'], {
        [styles['Footer--dark']]: isDarkMode,
      })}
    >
      <div className="container">
        <div className={styles['content-wrapper']}>
          <span
            className={cn(styles['copyrights'], {
              [styles['copyrights--dark']]: isDarkMode,
            })}
          >
            Copyright © 2023 / {currentYear} Lender Launch
          </span>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
