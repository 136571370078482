import cn from 'classnames';
import styles from './styles.module.scss';
import Typography from '@mui/material/Typography';
import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';

const setPath = (path, queryParams) => {
  const { financeTypeId } = queryParams;
  return financeTypeId && /\/lenders$/.test(path) ? `${path}?financeTypeId=${financeTypeId}` : path;
};

const Breadcrumbs = ({ pathname, isDarkMode, queryParams }) => {
  const item = pathname.split('/');
  item.shift();
  const pathnameItem = item;

  return (
    <MaterialBreadcrumbs
      separator="›"
      className={cn(styles['Breadcrumbs'], {
        [styles['Breadcrumbs--dark']]: isDarkMode,
      })}
    >
      {pathnameItem.map((path, i) => {
        const isCurrentPath = Number(i) === pathnameItem.length - 1;
        let combinedPath = '';
        for (let j = 0; j <= i; j++) {
          combinedPath += `/${pathnameItem[j]}`;
        }
        const fullPath = setPath(combinedPath, queryParams);

        return isCurrentPath ? (
          <Typography key={i} className={styles['typography']}>
            {path}
          </Typography>
        ) : (
          <NavLink key={i} to={fullPath} className={styles['nav-link']}>
            {path}
          </NavLink>
        );
      })}
    </MaterialBreadcrumbs>
  );
};

export { Breadcrumbs };
