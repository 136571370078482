const CLEAR_LENDER_FILTER_DIAPASONS = 'lenders/CLEAR_LENDER_FILTER_DIAPASONS';
const SET_LENDER_FILTER_DIAPASONS = 'lenders/SET_LENDER_FILTER_DIAPASONS';
const SET_FILTERED_LENDER = 'lenders/SET_FILTERED_LENDER';
const UPDATE_LENDER = 'lenders/UPDATE_LENDER';
const DELETE_LENDER = 'lenders/DELETE_LENDER';
const SAVE_LENDERS = 'lenders/SAVE_LENDERS';
const SAVE_LENDER = 'lenders/SAVE_LENDER';

export {
  CLEAR_LENDER_FILTER_DIAPASONS,
  SET_LENDER_FILTER_DIAPASONS,
  SET_FILTERED_LENDER,
  UPDATE_LENDER,
  DELETE_LENDER,
  SAVE_LENDERS,
  SAVE_LENDER,
};
